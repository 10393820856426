<template>
  <b-sidebar
    id="add-new-user-sidebar"
    :visible="isToggleSidebar"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => toggleSidebar(val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          {{ titleForm }}
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- Form -->
      <b-form
        class="p-2"
        @reset.prevent="resetForm"
      >
        <div
          v-if="isBusy"
          class="text-center text-primary my-2"
        >
          <b-spinner
            variant="primary"
            class="align-middle"
          />
          <strong class="ml-1">Cargando...</strong>
        </div>

        <h6
          class="text-info"
        >
          <feather-icon icon="AlertCircleIcon" />
          <span class="align-middle ml-25">Datos Generales</span>
        </h6>

        <hr style="border-top: 2px solid #00cfe8;margin-bottom: 1rem !important;margin-top: 0 !important;">

        <b-form-group
          label="Tipo"
          label-for="typeInstitution"
        >
          <v-select
            id="typeInstitution"
            v-model="typeInstitutionSel"
            label="description"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="typeInstitutions"
          />
        </b-form-group>

        <b-form-group
          label="Nombre"
          label-for="name"
        >
          <b-form-input
            id="name"
            v-model="formData.name"
            type="text"
          />
        </b-form-group>

        <h6
          class="text-info"
        >
          <feather-icon icon="MapPinIcon" />
          <span class="align-middle ml-25">Ubicación</span>
        </h6>

        <hr style="border-top: 2px solid #00cfe8;margin-bottom: 1rem !important;margin-top: 0 !important;">

        <b-form-group
          label="Seleccione Departamento"
          label-for="dpto"
        >
          <v-select
            v-model="dptoSel"
            :reduce="m => m.idUbigeo"
            label="description"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="dptos"
            @input="getUbigeos('prov', dptoSel.slice(0, 2))"
          />
        </b-form-group>
        <b-form-group
          label="Seleccione Provincia"
          label-for="prov"
        >
          <v-select
            v-model="provSel"
            :reduce="m => m.idUbigeo"
            label="description"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="provs"
            @input="getUbigeos('dist', provSel.slice(0, 4))"
          />
        </b-form-group>
        <b-form-group
          label="Seleccione Distrito"
          label-for="dist"
        >
          <v-select
            v-model="distSel"
            :reduce="m => m.idUbigeo"
            label="description"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="dists"
          />
        </b-form-group>
        <b-form-group
          label="Dirección"
          label-for="address"
        >
          <b-form-input
            id="address"
            v-model="formData.address"
          />
        </b-form-group>

        <h6
          class="text-info"
        >
          <feather-icon icon="PhoneCallIcon" />
          <span class="align-middle ml-25">Datos de Contacto</span>
        </h6>

        <hr style="border-top: 2px solid #00cfe8;margin-bottom: 1rem !important;margin-top: 0 !important;">

        <b-row>
          <b-col
            cols="12"
            md="12"
          >
            <b-form-group
              label="Correo"
              label-for="email"
            >
              <b-form-input
                id="email"
                v-model="formData.email"
                type="email"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="12"
          >
            <b-form-group
              label="Teléfono"
              label-for="phone"
            >
              <b-form-input
                id="phone"
                v-model="formData.phone"
                type="tel"
              />
            </b-form-group>
          </b-col>
        </b-row>

        <!-- Form Actions -->
        <div class="d-flex mt-2">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-2"
            type="button"
            @click="saveForm"
          >
            Guardar
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="button"
            variant="outline-secondary"
            @click="hide"
          >
            Cancelar
          </b-button>
        </div>
      </b-form>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BRow, BCol, BSidebar, BForm, BFormGroup, BFormInput, BButton, BSpinner,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ref } from '@vue/composition-api'
import { required } from '@validations'
// import moment from 'moment'
import Ripple from 'vue-ripple-directive'
import store from '@/store'

export default {
  components: {
    BRow,
    BCol,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    vSelect,
    BSpinner,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isToggleSidebar',
    event: 'update:is-toggle-sidebar',
  },
  props: {
    isToggleSidebar: {
      type: Boolean,
      required: true,
    },
    dataEdit: {
      type: Object,
      required: false,
      default: null,
    },
    formType: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      required,
    }
  },
  setup(props, { emit }) {
    const titleForm = ref('')
    const isBusy = ref(false)
    const formData = ref({})
    const dptoSel = ref('220000')
    const provSel = ref(null)
    const distSel = ref(null)
    const dptos = ref([])
    const provs = ref([])
    const dists = ref([])
    const typeInstitutions = ref([])
    const typeInstitutionSel = ref(null)

    const configDP = ref(
      {
        enableTime: false,
        dateFormat: 'd/m/Y',
        altInput: true,
        altFormat: 'd/m/Y',
      },
    )

    const resetForm = () => {
      formData.value = {}
    }

    const loadTypeInstitutions = async () => {
      await store.dispatch('setting/TYPE_INSTITUTION_FIND_ALL', {
        limit: 200,
        query: '',
        page: 1,
        sortBy: 'description%7CASC',
        type: 'list',
      })
        .then(response => {
          if (response) {
            typeInstitutions.value = response
          }
        })
    }

    const saveForm = async () => {
      if (typeInstitutionSel.value === null || typeInstitutionSel.value === '') {
        emit('error-data', { response: { data: { message: 'Debe seleccionar el tipo de Institución' } } })
        return
      }

      if (formData.value.name == null || formData.value.name === '') {
        emit('error-data', { response: { data: { message: 'Debe ingresar nombre de la Institución Aliada' } } })
        return
      }

      if (dptoSel.value == null) {
        emit('error-data', { response: { data: { message: 'Debe seleccionar departamento' } } })
        return
      }

      if (provSel.value === null) {
        emit('error-data', { response: { data: { message: 'Debe seleccionar provincia' } } })
        return
      }

      if (distSel.value === null) {
        emit('error-data', { response: { data: { message: 'Debe seleccionar distrito' } } })
        return
      }

      let service = 'ppptcd/INSTITUTION_SAVE'
      if (props.formType === 'edit') {
        service = 'ppptcd/INSTITUTION_UPDATE'
      }
      formData.value.ubigeo = distSel.value
      formData.value.typeInstitution = {
        idTypeInstitution: typeInstitutionSel.value.idTypeInstitution,
      }

      await store.dispatch(service, formData.value)
        .then(response => {
          emit('refetch-data', response)
          emit('update:is-toggle-sidebar', false)
        })
        .catch(error => {
          emit('error-data', error)
          emit('update:is-toggle-sidebar', false)
        })
    }

    const getUbigeos = async (type, codUbigeo) => {
      await store
        .dispatch('setting/UBIGEO_FIND_ALL', {
          get: type,
          codUbigeo,
        })
        .then(response => {
          if (type === 'dpto') {
            dptos.value = response
            provSel.value = null
            distSel.value = null
            provs.value = []
            dists.value = []
          }
          if (type === 'prov') {
            provs.value = response
            provSel.value = null
            distSel.value = null
            dists.value = []
          }
          if (type === 'dist') {
            dists.value = response
          }
        })
        .catch(error => {
          emit('error-data', error)
        })
    }

    const toggleSidebar = async val => {
      emit('update:is-toggle-sidebar', val)
      resetForm()
      loadTypeInstitutions()
      if (props.formType === 'new') {
        titleForm.value = 'Agregar Institución Aliada'
        await getUbigeos('dpto', '')
        await getUbigeos('prov', dptoSel.value.slice(0, 2))
      }
      if (props.formType === 'edit') {
        formData.value = {
          idInstitution: props.dataEdit.idInstitution,
          name: props.dataEdit.name,
          address: props.dataEdit.address,
          email: props.dataEdit.email,
          phone: props.dataEdit.phone,
          ubigeo: props.dataEdit.ubigeo,
          typeInstitution: props.dataEdit.typeInstitution,
        }
        typeInstitutionSel.value = props.dataEdit.typeInstitution
        dptoSel.value = `${props.dataEdit.ubigeo.slice(0, 2)}0000`
        await getUbigeos('dpto', '')
        await getUbigeos('prov', props.dataEdit.ubigeo.slice(0, 2))
        provSel.value = `${props.dataEdit.ubigeo.slice(0, 4)}00`
        await getUbigeos('dist', props.dataEdit.ubigeo.slice(0, 4))
        distSel.value = props.dataEdit.ubigeo
        titleForm.value = 'Editar Institución Aliada'
      }
    }

    return {
      titleForm,
      isBusy,
      formData,
      saveForm,
      toggleSidebar,
      resetForm,
      configDP,
      dptoSel,
      provSel,
      distSel,
      dptos,
      provs,
      dists,
      getUbigeos,
      loadTypeInstitutions,
      typeInstitutions,
      typeInstitutionSel,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
